@charset "UTF-8";

body {
    font: .85em/1.4 "微软雅黑", "Microsoft Yahei", 'Arial', 'san-serif';
    color: #666;
    margin: 0;
    padding: 0
}

img {
    max-width: 100%;
    height: auto;
    width: auto;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle
}

a,
button,
input {
    -webkit-tap-highlight-color: rgba(255, 0, 0, 0)
}

input {
    border: 0 none;
    margin: 0;
}

a:focus,
a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"]>input[type="button"]::-moz-focus-inner {
    border: 0;
    outline: 0;
}

input[type=radio],
input[type=checkbox] {
    margin-right: 5px
}

ul,
p {
    padding: 0;
    margin: 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 100
}

ul,
li {
    list-style: none
}

a {
    color: #666
}

a,
a:hover {
    text-decoration: none
}

a:focus {
    outline: none
}

textarea {
    resize: none;
}


/*
  这里是分割线
**/

header {
    width: 92%;
    margin: 0 auto;
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #020031), color-stop(100%, #6d3353));
    background: linear-gradient(45deg, #020031 0px, #6d3353 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2) inset, 0 -3px 7px rgba(0, 0, 0, 0.2) inset;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2) inset, 0 -3px 7px rgba(0, 0, 0, 0.2) inset;
    text-align: center;
    padding: 3% 4%;
    letter-spacing: 2px;
    color: #fff;
    font-size: 1.2em;
    text-shadow: 0 0 2px #999;
}

header a {
    color: #ddd;
}

.demo {
    width: 94%;
    margin: 20px auto;
    text-align: left;
}

.demo a {
    margin: 5px 4px;
    display: inline-block;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    padding: 4px 10px;
}

.demo h3 {
    margin: 15px 0;
    color: #000;
}

.desc {
    width: 100%;
    overflow: auto;
    margin: 25px auto;
    background-color: #fafafa;
    border-left: 4px solid #72B332;
}

.desc h3 {
    width: 96%;
    margin: 0 auto;
    padding: 20px 0;
}

pre {
    width: 94%;
    margin: auto auto 10px;
    font-size: 1.1em;
}


/*
  这里是分割线
**/

.mr10 {
    margin-right: 10px;
}

.fl {
    float: left;
}

#HBox {
    width: 898px;
    height: 494px;
    margin: 0 auto;
    display: none;
    background-color: #ffffff;
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -247px 0 0 -449px;
    z-index: 100000;
    border: none;
    padding-left: 70px;
    box-sizing: border-box;
}

.list {
    padding: 40px 0 0;
}

.list li {
    /* margin: 10px auto auto; */
    overflow: hidden;
}

.list li strong {
    float: left;
    display: inline-block;
    margin-right: 15px;
    text-align: right;
    font-weight: 400;
}

/* .list .fl {
    width: 72%;
} */

.ipt {
    width: 100%;
    text-indent: 5px;
    border: 1px solid #d2d2d2;
    padding: 8.5px 0;
}

.submitBtn {
    width: 100%;
    height: 54px;
    line-height: 54px;
    cursor: pointer;
    margin-top: 10px;
    display: inline-block;
    text-align: center;
    background-color: #ff5500;
    color: #fff;
    padding: 0;
    border-radius: 5px;
    font-size: 20px;
}

.yijun-cooperation {
    margin-top: 48px;
    text-align: center;
    font-size: 28px;
    line-height:28px;
    color: #444;
    font-weight: 400;
}

.submitBtn:hover,
.submitBtn:disabled {
    opacity: .8;
    -webkit-opacity: .8;
}





/*
  这里是分界线
**/

.animated {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

.animated.hinge {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s
}

@-webkit-keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px)
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px)
    }
}

@keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
    40% {
        -webkit-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        transform: translateY(-30px)
    }
    60% {
        -webkit-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        transform: translateY(-15px)
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce
}

@-webkit-keyframes flash {
    0%,
    100%,
    50% {
        opacity: 1
    }
    25%,
    75% {
        opacity: 0
    }
}

@keyframes flash {
    0%,
    100%,
    50% {
        opacity: 1
    }
    25%,
    75% {
        opacity: 0
    }
}

.flash {
    -webkit-animation-name: flash;
    animation-name: flash
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
    50% {
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1)
    }
    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse
}

@-webkit-keyframes rubberBand {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    30% {
        -webkit-transform: scaleX(1.25) scaleY(0.75);
        transform: scaleX(1.25) scaleY(0.75)
    }
    40% {
        -webkit-transform: scaleX(0.75) scaleY(1.25);
        transform: scaleX(0.75) scaleY(1.25)
    }
    60% {
        -webkit-transform: scaleX(1.15) scaleY(0.85);
        transform: scaleX(1.15) scaleY(0.85)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes rubberBand {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
    30% {
        -webkit-transform: scaleX(1.25) scaleY(0.75);
        -ms-transform: scaleX(1.25) scaleY(0.75);
        transform: scaleX(1.25) scaleY(0.75)
    }
    40% {
        -webkit-transform: scaleX(0.75) scaleY(1.25);
        -ms-transform: scaleX(0.75) scaleY(1.25);
        transform: scaleX(0.75) scaleY(1.25)
    }
    60% {
        -webkit-transform: scaleX(1.15) scaleY(0.85);
        -ms-transform: scaleX(1.15) scaleY(0.85);
        transform: scaleX(1.15) scaleY(0.85)
    }
    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

.rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand
}

@-webkit-keyframes shake {
    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px)
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px)
    }
}

@keyframes shake {
    0%,
    100% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        transform: translateX(-10px)
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px)
    }
}

.shake {
    -webkit-animation-name: shake;
    animation-name: shake
}

@-webkit-keyframes swing {
    20% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg)
    }
    40% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg)
    }
    60% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg)
    }
    80% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg)
    }
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}

@keyframes swing {
    20% {
        -webkit-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        transform: rotate(15deg)
    }
    40% {
        -webkit-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        transform: rotate(-10deg)
    }
    60% {
        -webkit-transform: rotate(5deg);
        -ms-transform: rotate(5deg);
        transform: rotate(5deg)
    }
    80% {
        -webkit-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        transform: rotate(-5deg)
    }
    100% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}

.swing {
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing
}

@-webkit-keyframes tada {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    10%,
    20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg)
    }
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg)
    }
    40%,
    60%,
    80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg)
    }
    100% {
        -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0)
    }
}

@keyframes tada {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
    10%,
    20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        -ms-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg)
    }
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg)
    }
    40%,
    60%,
    80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg)
    }
    100% {
        -webkit-transform: scale(1) rotate(0);
        -ms-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0)
    }
}

.tada {
    -webkit-animation-name: tada;
    animation-name: tada
}

@-webkit-keyframes wobble {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%)
    }
    15% {
        -webkit-transform: translateX(-25%) rotate(-5deg);
        transform: translateX(-25%) rotate(-5deg)
    }
    30% {
        -webkit-transform: translateX(20%) rotate(3deg);
        transform: translateX(20%) rotate(3deg)
    }
    45% {
        -webkit-transform: translateX(-15%) rotate(-3deg);
        transform: translateX(-15%) rotate(-3deg)
    }
    60% {
        -webkit-transform: translateX(10%) rotate(2deg);
        transform: translateX(10%) rotate(2deg)
    }
    75% {
        -webkit-transform: translateX(-5%) rotate(-1deg);
        transform: translateX(-5%) rotate(-1deg)
    }
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%)
    }
}

@keyframes wobble {
    0% {
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%)
    }
    15% {
        -webkit-transform: translateX(-25%) rotate(-5deg);
        -ms-transform: translateX(-25%) rotate(-5deg);
        transform: translateX(-25%) rotate(-5deg)
    }
    30% {
        -webkit-transform: translateX(20%) rotate(3deg);
        -ms-transform: translateX(20%) rotate(3deg);
        transform: translateX(20%) rotate(3deg)
    }
    45% {
        -webkit-transform: translateX(-15%) rotate(-3deg);
        -ms-transform: translateX(-15%) rotate(-3deg);
        transform: translateX(-15%) rotate(-3deg)
    }
    60% {
        -webkit-transform: translateX(10%) rotate(2deg);
        -ms-transform: translateX(10%) rotate(2deg);
        transform: translateX(10%) rotate(2deg)
    }
    75% {
        -webkit-transform: translateX(-5%) rotate(-1deg);
        -ms-transform: translateX(-5%) rotate(-1deg);
        transform: translateX(-5%) rotate(-1deg)
    }
    100% {
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%)
    }
}

.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble
}

@-webkit-keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.3);
        transform: scale(.3)
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(1.05);
        transform: scale(1.05)
    }
    /* 70% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    } */
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.3);
        -ms-transform: scale(.3);
        transform: scale(.3)
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05)
    }
    /* 70% {
        -webkit-transform: scale(.9);
        -ms-transform: scale(.9);
        transform: scale(.9)
    } */
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

.bounceIn {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn
}